/* Hand animation */
.scroll-hint {
  position: absolute;
  top: 30px;
  left: 80px;
  font-size: 24px;
  z-index: 100;
  animation: moveHand 4s ease-in-out 2 alternate, fadeOut 10s forwards;
}

@keyframes moveHand {
  0% {
    transform: translateX(90px);
  }
  100% {
    transform: translateX(-60px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.menu-nav {
  /* Hide scrollbar for different browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.menu-nav::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

a {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}

.padding-xhuge {
  padding-bottom: 3rem;
  padding-top: 4rem;
}

.menu-title {
  font-size: 1.1rem;
}

/* slider style */

.swiper {
  width: 60%;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
/* styles.css */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s;
}

.backdrop.show {
  opacity: 1;
  visibility: visible;
}

/* Smooth Pulsating Ripple Loader */
.loader {
  position: relative;
  width: 50px;
  height: 50px;
}

.loader div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #f7da72;
  animation: ripple 1.5s ease-out infinite;
}

.loader div:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes ripple {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
